import { Link } from "gatsby"
import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import axios from "axios"

import Header from "./header"
import Wrapper from "./wrapper"

import "./layout.css"

const Layout = ({ children, style }) => {
  const [loading, setLoading] = React.useState(false)
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />

      <main style={style}>{children}</main>
      <footer style={{ height: "auto", background: "#212121" }}>
        <Wrapper padding>
          <h4 style={{ color: "#FFF" }}>Wichtige Information</h4>
          <p style={{ color: "#FFF", opacity: 0.6 }}>
            Nach dem Abschluss des Zeitzugangs bei just2.games erhält der
            Benutzer die Möglichkeit, alle bereitgestellten Dienste - Spiele für
            die Dauer des Zeitzugangs zu streamen. Einmalzugang 7-Tage für
            €4.99. Die angeführten Preise beinhalten die Mehrwertsteuer. Die
            Gebühr beinhaltet nicht die Nutzung des mobilen Internets.
            Dienstleister: Brain Offices GmbH, Mühlenstr. 8 a, D-14167 Berlin.
            Haben Sie Fragen? Kontaktieren Sie uns unter der telefonischen
            Hotline: 08005895405 oder beim E-Mail-Support: info@care-tech.info
          </p>
          <ul id="navFooter">
            <Link to="/">Home</Link>
            <Link to="/agb">AGB</Link>
            <Link to="/help">Hilfe &amp; Kontakt</Link>
            <Link to="/imprint">Impressum</Link>
            <Link to="/widerruf">Rücktrittsrecht</Link>
            <Link to="/privacy">Datenschutz</Link>
          </ul>
        </Wrapper>
      </footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
