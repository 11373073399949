import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import Wrapper from "./wrapper"
import Logo from "../images/logo-1.png"

const Header = ({ siteTitle }) => (
  <header
    className="backdrop"
    style={{
      background: `rgba(0,0,0,0.3)`,
      position: "fixed",
      top: 0,
      left: 0,
      width: "100vw",
      zIndex: 999,
      display: "flex",
      alignItems: "center",
      height: 60,
    }}
  >
    <Wrapper>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          height: 60,
          alignItems: "center",
        }}
      >
        <img src={Logo} alt="just2.games" width="25px" height="40px" />
        <h1 style={{ margin: 0, fontSize: "1rem", marginLeft: 14 }}>
          <Link
            to="/"
            style={{
              color: `white`,
              textDecoration: `none`,
            }}
          >
            {siteTitle}
          </Link>
        </h1>
      </div>
    </Wrapper>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
